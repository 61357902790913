import styled from 'styled-components';
import React from 'react';
import Modal from './Modal';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { Flex } from '../Flex';
import PhoneIcon from '../icons/PhoneIcon';
import EmailIcon from '../icons/EmailIcon';
import { useTranslation } from 'react-i18next';

export const InfoModalStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .questionMarkIconModal {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e24335;
        margin-bottom: 20px;
        border-radius: 50%;
    }
    h2 {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ff6700;
        margin-bottom: 10px;
    }
    p {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #292929;
        margin-bottom: 20px;
    }
    .gotIt {
        margin-bottom: 20px;
    }
    .contactSupport {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #b7b7b7;
        margin-bottom: 10px;
    }
    .contactSupportIcon {
        width: 45px;
        height: 45px;
        background-color: #00c437;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.email {
            background-color: #e4493b;
        }
    }
`;

interface IProps {
    onCloseModal: () => void;
}

const InfoModal: React.FC<IProps> = (props) => {
    const { onCloseModal } = props;
    const { t } = useTranslation();
    return (
        <Modal onClickOverlay={onCloseModal}>
            <InfoModalStyle>
                <h2>{t('notSureWhyWeAreAskingAboutThis')}</h2>
                <p>{t('questionInfo')}</p>
                <Button
                    text={t('gotIt') || ''}
                    className={'gotIt'}
                    handleClick={onCloseModal}
                />
                <p className={'contactSupport'}>{t('contactSupport')}</p>
                <Flex alignItems={'center'} justifyContent={'center'} gap={10}>
                    <Link to={'tel:15617'}>
                        <div className="contactSupportIcon">
                            <PhoneIcon />
                        </div>
                    </Link>
                    <Link to={'mailto:cs@dutrade.com'}>
                        <div className="contactSupportIcon email">
                            <EmailIcon />
                        </div>{' '}
                    </Link>
                </Flex>
            </InfoModalStyle>
        </Modal>
    );
};
export default InfoModal;
