import styled from 'styled-components';
import React, { ReactNode } from 'react';
export const ModalStyle = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    .overlay {
        background-color: rgba(37, 71, 97, 0.5);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 100%;
        .modal-content {
            max-width: 500px;
            padding: 20px;
            background-color: #ffffff;
            border-radius: 10px;
            position: relative;
            z-index: 2000;
        }
    }
`;

interface IProps {
    children?: ReactNode;
    className?: string;
    onClickOverlay?: () => void;
}

const Modal: React.FC<IProps> = (props) => {
    const { children, className, onClickOverlay } = props;
    return (
        <ModalStyle className={className}>
            <div
                className="overlay"
                onClick={() => {
                    if (onClickOverlay) {
                        onClickOverlay();
                    }
                }}
            >
                <div
                    className="modal-content"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </div>
        </ModalStyle>
    );
};
export default Modal;
