import './styles/app.scss';
import SimpleSlider from './components/Onboarding';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Register from './components/register/Register';
import PreRegister from './components/register/PreRegister';
import RegisterSuccess from './components/RegisterSuccess';
import TermsAndConditions from './components/register/TermsAndConditions';
import Layout from './components/Layout';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/register-success" element={<RegisterSuccess />} />
                <Route path="/pre-register" element={<PreRegister />} />
                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                />
                <Route path="/" element={<SimpleSlider />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
