import React from 'react';
import styled from 'styled-components';

interface IProps {
    marginRight: number;
}
export const RadioButton = styled.input<IProps>`
    margin-right: ${(props) => props.marginRight}px;
    appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #d9d9d9;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:checked {
        background-color: #ff6700;
    }
`;
