import { uploadApi } from './uploadApi';

const uploadFileApi = uploadApi.injectEndpoints({
    endpoints: (build) => ({
        uploadFile: build.mutation<{ file_path: string }, Partial<FormData>>({
            query: (data) => ({
                url: `files/upload`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useUploadFileMutation } = uploadFileApi;
