import React, { FC } from 'react';
import styled from 'styled-components';
import Button from '../../ui/Button';
import InfoQuestionButton from '../InfoQuestionButton';
import { Field, FormikValues } from 'formik';
import CheckboxField from '../../ui/fields/checkbox/CheckboxField';
import { useDispatch } from 'react-redux';
import { setShowCheckboxInfo } from '../../../store/reducers/registerSlice';
import { useTranslation } from 'react-i18next';
import arCustodianContract from '../../../files/AR Custodian Contract -- bookkeeper.htm';
import arSecuritiesBrokerageAccountContract from '../../../files/AR Securities Brokerage Account Contract.htm';
import enCustodianContract from '../../../files/EN Custodian Contract -- Book keeper.htm';
import enSecuritiesBrokerageAccountContract from '../../../files/EN Securities Brokerage Account Contract.htm';

const Question10Style = styled.div`
    .checkboxFields {
        span.link {
            color: #e24335;
        }
    }
`;

interface IProps {
    isLoading: boolean;
    isValid: boolean;
    values: FormikValues;
}

const Question10: FC<IProps> = (props) => {
    const { isValid, isLoading, values } = props;
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    return (
        <Question10Style>
            <h3 className="title">
                {t('allGoodJustCheckAllBoxes')}
                <InfoQuestionButton />
            </h3>
            <div className="fields checkboxFields">
                <Field
                    name={`checkbox1`}
                    label={<span>{t('checkbox1')}</span>}
                    component={CheckboxField}
                />
                <Field
                    name={`checkbox2`}
                    label={
                        <span>
                            {t('checkbox2_1') + ' '}
                            <span
                                className={'link'}
                                onClick={() => {
                                    dispatch(
                                        setShowCheckboxInfo({
                                            show: true,
                                            title: t('checkbox2_2'),
                                            file:
                                                i18n.dir() === 'rtl'
                                                    ? arSecuritiesBrokerageAccountContract
                                                    : enSecuritiesBrokerageAccountContract,
                                        }),
                                    );
                                }}
                            >
                                {t('checkbox2_2')}
                            </span>
                            <span> {' ' + t('checkbox2_3')}</span>
                        </span>
                    }
                    component={CheckboxField}
                />
                <Field
                    name={`checkbox3`}
                    label={
                        <span>
                            {t('checkbox3_1') + ' '}
                            <span
                                className={'link'}
                                onClick={() => {
                                    dispatch(
                                        setShowCheckboxInfo({
                                            show: true,
                                            title: t('checkbox3_2'),
                                            file:
                                                i18n.dir() === 'rtl'
                                                    ? arCustodianContract
                                                    : enCustodianContract,
                                        }),
                                    );
                                }}
                            >
                                {t('checkbox3_2')}
                            </span>
                            <span>{' ' + t('checkbox3_3')}</span>
                        </span>
                    }
                    component={CheckboxField}
                />
            </div>
            <Button
                text={t('I agree on all Terms') || ''}
                type={'submit'}
                isLoading={isLoading}
                disabled={isLoading || !isValid}
            />
        </Question10Style>
    );
};
export default Question10;
