import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegisterQuestion } from '../../models/question';
import { RootState } from '../index';
import { WhatDoYouDoEnum } from '../../enums/WhatDoYouDoEnum';
interface IImage {
    original: File | null;
    upload: File | null;
}

interface ICheckboxInfo {
    show: boolean;
    file: any;
    title: string;
}
export interface IRegisterState {
    currentQuestion: IRegisterQuestion | null;
    current_job: WhatDoYouDoEnum;
    showCheckboxInfo: ICheckboxInfo;
    isCanSendOtp: boolean;
    frontIdImage: IImage;
    backIdImage: IImage;
    faceImage: IImage;
    frontIdUrl: string;
    backIdUrl: string;
    faceUrl: string;
}

const initialState: IRegisterState = {
    currentQuestion: null,
    current_job: WhatDoYouDoEnum.NotEmployed,
    showCheckboxInfo: { show: false, title: '', file: undefined },
    isCanSendOtp: true,
    frontIdImage: { original: null, upload: null },
    backIdImage: { original: null, upload: null },
    faceImage: { original: null, upload: null },
    frontIdUrl: '',
    backIdUrl: '',
    faceUrl: '',
};
export const registerSlice = createSlice({
    name: 'registerSlice',
    initialState,
    reducers: {
        setCurrentQuestion: (
            state,
            action: PayloadAction<IRegisterQuestion>,
        ) => {
            state.currentQuestion = action.payload;
        },
        setCurrentJob: (state, action: PayloadAction<WhatDoYouDoEnum>) => {
            state.current_job = action.payload;
        },
        setIsCanSendOtp: (state, action: PayloadAction<boolean>) => {
            state.isCanSendOtp = action.payload;
        },
        setShowCheckboxInfo: (state, action: PayloadAction<ICheckboxInfo>) => {
            state.showCheckboxInfo = action.payload;
        },
        setFrontIdImage: (state, action: PayloadAction<IImage>) => {
            state.frontIdImage = action.payload;
        },
        setBackIdImage: (state, action: PayloadAction<IImage>) => {
            state.backIdImage = action.payload;
        },
        setFaceImage: (state, action: PayloadAction<IImage>) => {
            state.faceImage = action.payload;
        },
        setFrontIdUrl: (state, action: PayloadAction<string>) => {
            state.frontIdUrl = action.payload;
        },
        setBackIdUrl: (state, action: PayloadAction<string>) => {
            state.backIdUrl = action.payload;
        },
        setFaceUrl: (state, action: PayloadAction<string>) => {
            state.faceUrl = action.payload;
        },
    },
});

export const {
    setCurrentQuestion,
    setCurrentJob,
    setShowCheckboxInfo,
    setFaceImage,
    setFrontIdImage,
    setBackIdImage,
    setIsCanSendOtp,
    setBackIdUrl,
    setFaceUrl,
    setFrontIdUrl,
} = registerSlice.actions;
export const selectRegister = (state: RootState) => state.registerSlice;

export default registerSlice.reducer;
