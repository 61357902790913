import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../ui/Container';
import { Flex } from '../ui/Flex';
import ArrowIcon from '../ui/icons/ArrowIcon';
import { useNavigate } from 'react-router-dom';
import { useGetLookupsQuery } from '../../store/RTKQuery/lookups/getLookups';
import { v4 as uuid4 } from 'uuid';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUtility } from '../../store/reducers/utilitySlice';
const TermsAndConditionsStyle = styled.div`
    background-color: #f1f1f1;
    padding: 30px 20px;
    min-height: 100vh;
    .backIcon {
        transform: rotate(0);
        cursor: pointer;
        path {
            transition: fill 0.25s;
        }
        &:hover {
            path {
                fill: red;
            }
        }
    }

    h3.title {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #ff6700;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 5px;
        flex-wrap: wrap;
        span.blue {
            color: #0828b2;
        }
    }

    .box {
        background-color: #ffffff;
        border: 1px solid #efefef;
        border-radius: 20px;
        margin-bottom: 15px;
        padding: 15px;
        &:last-of-type {
            margin-bottom: 0;
        }
        h4 {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #ff6700;
            margin-bottom: 5px;
        }
        p {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 22px;
            color: #4f4f4f;
        }
    }
`;

interface IProps {}

const TermsAndConditions: FC<IProps> = (props) => {
    const navigate = useNavigate();
    const { lang } = useSelector(selectUtility);
    const { data } = useGetLookupsQuery({
        lang: lang,
    });
    const { t } = useTranslation();
    return (
        <TermsAndConditionsStyle>
            <Container>
                <Flex
                    gap={25}
                    justifyContent={'space-between'}
                    marginBottom={25}
                >
                    <ArrowIcon
                        className={'backIcon'}
                        onClick={() => {
                            navigate('/pre-login');
                        }}
                    />
                </Flex>
                <h3 className="title">{t('termsConditions')}</h3>
                {data &&
                    data.data.agreements.map((item) => (
                        <div className="box" key={uuid4()}>
                            <h4>{item.title}</h4>
                            <p>{item.description}</p>
                        </div>
                    ))}
            </Container>
        </TermsAndConditionsStyle>
    );
};

export default TermsAndConditions;
