import { ISelectItem } from '../models/select-item';
import { ILookupsModel } from '../models/lookups';

export const convertToSelectItem = (data: string[]): ISelectItem[] => {
    let arr: ISelectItem[] = [];
    data.map((item) => {
        arr.push({
            label: item,
            value: item,
        });
    });
    return arr;
};

export const convertLookupsToSelectItem = (
    data: ILookupsModel[],
    valueType: 'id' | 'name' | 'code',
): ISelectItem[] => {
    let arr: ISelectItem[] = [];
    if (valueType === 'id') {
        data.map((item) => {
            arr.push({
                label: item.name,
                value: item.id,
            });
        });
    } else if (valueType === 'name') {
        data.map((item) => {
            arr.push({
                label: item.name,
                value: item.name,
            });
        });
    } else if (valueType === 'code') {
        data.map((item) => {
            if (item.code) {
                arr.push({
                    label: item.name,
                    value: item.code,
                });
            }
        });
    }
    return arr;
};
