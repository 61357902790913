import { authApi } from './authApi';

const sendOTPApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        sendOTP: build.mutation<
            {
                success: boolean;
                message: string;
            },
            Partial<{ mobile: string }>
        >({
            query: (data) => ({
                url: `sendotp`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useSendOTPMutation } = sendOTPApi;
