import { authApi } from './authApi';
import { IRegisterModel } from '../../../models/register';

const registerApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        register: build.mutation<{}, Partial<IRegisterModel>>({
            query: (data) => ({
                url: `customer/register`,
                method: 'POST',
                body: JSON.parse(JSON.stringify(data)),
            }),
        }),
    }),
});

export const { useRegisterMutation } = registerApi;
