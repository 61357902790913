import { Field } from 'formik';
import SelectField from './SelectField';
import { ISelectItem } from '../../../../models/select-item';
import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

interface IProps {
    name: string;
    onChange?: (newValue: ISelectItem) => void;
    label?: string;
    className?: string;
    placeholder?: string;
    addAllOption?: boolean;
    isSearchable?: boolean;
    autoSelectFirst?: boolean;
    isDisabled?: boolean;
    params?: any;
    insideModal?: boolean;
    isFullValue?: boolean;
    isClearable?: boolean;
    data: ISelectItem[];
}

const SelectFieldQuery: FC<IProps> = (props) => {
    const {
        name,
        label,
        placeholder,
        onChange,
        addAllOption = false,
        isSearchable,
        autoSelectFirst = false,
        isDisabled,
        className,
        insideModal,
        isFullValue,
        isClearable,
        data,
    } = props;
    const [options, setOptions] = useState<ISelectItem[]>([]);
    const [option, setOption] = useState<ISelectItem>();
    useEffect(() => {
        if (!isEmpty(data) && data !== undefined) {
            setOptions(data);
            if (autoSelectFirst) {
                setOption(data[0]);
            }
        }
    }, [autoSelectFirst, data]);
    return (
        <>
            <Field
                options={options}
                name={name}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                option={option}
                addAllOption={addAllOption}
                isSearchable={isSearchable}
                autoSelectFirst={autoSelectFirst}
                isDisabled={isDisabled}
                className={className}
                insideModal={insideModal}
                isClearable={isClearable}
                isFullValue={isFullValue}
                component={SelectField}
            />
        </>
    );
};
export default SelectFieldQuery;
