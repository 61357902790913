import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectRegister } from '../../../store/reducers/registerSlice';

const CheckboxInfoStyle = styled.div`
    .box {
        background-color: #ffffff;
        border: 1px solid #efefef;
        border-radius: 20px;
        margin-bottom: 15px;
        padding: 15px;
    }
    iframe {
        width: 100%;
        border: none;
        height: 450px;
    }
`;

interface IProps {}

const CheckboxInfo: FC<IProps> = (props) => {
    const { showCheckboxInfo } = useSelector(selectRegister);
    return (
        <CheckboxInfoStyle>
            <h3 className="title">{showCheckboxInfo.title}</h3>
            {showCheckboxInfo.file && (
                <iframe
                    title={showCheckboxInfo.title}
                    src={showCheckboxInfo.file}
                ></iframe>
            )}
        </CheckboxInfoStyle>
    );
};

export default CheckboxInfo;
