import { authApi } from './authApi';

const verifyOTPApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        verifyOTP: build.mutation<
            {
                success: boolean;
                message: string;
                data: string;
            },
            Partial<{ verification_code: string; mobile: string }>
        >({
            query: (data) => ({
                url: `verify-code`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useVerifyOTPMutation } = verifyOTPApi;
