import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import otpImage from '../../../images/otp.png';
import { Flex } from '../../ui/Flex';
import Button from '../../ui/Button';
import { useSendOTPMutation } from '../../../store/RTKQuery/auth/sendOTP';
import { FormikValues } from 'formik';
import { useVerifyOTPMutation } from '../../../store/RTKQuery/auth/verifyOTP';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRegister,
    setCurrentQuestion,
    setIsCanSendOtp,
} from '../../../store/reducers/registerSlice';
import { questions } from '../Register';
import { toast } from 'react-toastify';
import PinInput from 'react-pin-input';
import { useTranslation } from 'react-i18next';
import { CountDownRender } from '../../ui/CountDownRender';
import Countdown from 'react-countdown';

const Question3OTPStyle = styled.div`
    .box {
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
        img {
            max-width: 100%;
            margin-bottom: 30px;
        }
        h3 {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #ff6700;
            margin-bottom: 20px;
        }
        .info {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #4f4f4f;
            margin-bottom: 20px;
            margin-top: 0;
            span {
                color: #000000;
            }
        }
        .didNotReceiveCode {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #4f4f4f;
            margin-bottom: 15px;
        }
        .resendCode {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #0828b2;
            margin-bottom: 20px;
            cursor: pointer;
        }
        .codeInput {
            margin-bottom: 30px;
            
          body[dir="rtl"] &{
            direction: ltr;
          }
            .pincode-input-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                input {
                    padding: 10px !important;
                    border: 2px solid #b7b7b7 !important;
                    border-top: none !important;
                    border-right: none !important;
                    border-left: none !important;
                    font-family: 'Neue Helvetica', serif;
                    font-style: normal;
                    font-weight: 450;
                    font-size: 20px;
                    line-height: 22px;
                    text-align: center !important;
                    width: 100% !important;
                    color: #ff6700;
                    &:focus-visible {
                        outline: none;
                    }
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    &[type='number'] {
                        -moz-appearance: textfield;
                    }
                }
            }
        }
    }
`;

interface IProps {
    values: FormikValues;
}
const Question3OTP: FC<IProps> = (props) => {
    const { values } = props;
    const [code, setCode] = useState<string>('');
    const [sendOtp, sendOtpResult] = useSendOTPMutation();
    const [verifyOtp, verifyOtpResult] = useVerifyOTPMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isCanSendOtp } = useSelector(selectRegister);
    const [time, setTime] = useState<number>(Date.now());
    const [canResend, setCanResend] = useState<boolean>(true);
    const countDownRef = useRef<Countdown>(null);
    useEffect(() => {
        if (countDownRef.current != null && !canResend) {
            if (countDownRef.current.api !== undefined) {
                countDownRef.current.api.start();
            }
        }
    }, [canResend]);
    const handleComplete = () => {
        setTime(Date.now());
        setCanResend(true);
    };
    useEffect(() => {
        if (isCanSendOtp) {
            sendOtp({
                mobile: values.mobile,
            });
        }
    }, []);
    useEffect(() => {
        if (sendOtpResult.isSuccess && sendOtpResult.data.success) {
            dispatch(setIsCanSendOtp(false));
            setCanResend(false);
            setTime(Date.now() + 29000);
        }
    }, [sendOtpResult.data, sendOtpResult.isSuccess]);
    useEffect(() => {
        if (verifyOtpResult.isSuccess && verifyOtpResult.data.success) {
            dispatch(
                setCurrentQuestion(questions.filter((q) => q.page === 4)[0]),
            );
        } else {
            toast(verifyOtpResult.data?.data, {
                containerId: 'errorToastContainer',
                type: 'error',
            });
        }
    }, [verifyOtpResult.isSuccess]);
    return (
        <Question3OTPStyle>
            <Flex direction={'column'} alignItems={'center'} className={'box'}>
                <img src={otpImage} />
                <h3>{t('otpVerification')}</h3>
                <p
                    className={'info'}
                    style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}
                >
                    {t('pleaseEnterTheOtpSentToYourMobile')}
                    <span
                        style={{
                            display: 'inline-block',
                            direction: 'ltr',
                            textAlign: 'center',
                            flex: 1,
                            minWidth: 'max-content',
                        }}
                    >
                        {' ' + values.mobile}
                    </span>
                </p>
                <div className="codeInput">
                    <PinInput
                        type="numeric"
                        length={4}
                        onChange={(value) => {
                            setCode(value);
                        }}
                        inputMode="numeric"
                    />
                </div>
                <Flex
                    direction={'column'}
                    gap={10}
                    width={'100%'}
                    alignItems={'center'}
                >
                    <p className={'didNotReceiveCode'}>
                        {t('didNotReceiveCode')}
                    </p>
                    <Countdown
                        date={time}
                        renderer={CountDownRender}
                        onComplete={handleComplete}
                        ref={countDownRef}
                    />
                    {canResend && (
                        <p
                            className={'resendCode'}
                            onClick={() => {
                                sendOtp({
                                    mobile: values.mobile,
                                });
                            }}
                        >
                            {t('resendCode')}
                        </p>
                    )}
                    <Button
                        disabled={
                            code.length !== 4 || verifyOtpResult.isLoading
                        }
                        text={t('next') || ''}
                        type={'button'}
                        isLoading={verifyOtpResult.isLoading}
                        handleClick={() => {
                            verifyOtp({
                                verification_code: code,
                                mobile: values.mobile,
                            });
                        }}
                    />
                </Flex>
            </Flex>
        </Question3OTPStyle>
    );
};
export default Question3OTP;
