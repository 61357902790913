import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getFetchBaseQueryHeader } from '../getFetchBaseQuery';

export const lookupsApi = createApi({
    reducerPath: 'lookupsApi',
    baseQuery: getFetchBaseQueryHeader({
        accept: 'application/json',
    }),
    endpoints: (build) => ({}),
});
