import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getFetchBaseQueryHeader } from '../getFetchBaseQuery';

export const uploadApi = createApi({
    reducerPath: 'uploadApi',
    baseQuery: getFetchBaseQueryHeader({
        accept: 'application/json',
    }),
    endpoints: (build) => ({}),
});
