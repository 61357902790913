import styled from 'styled-components';

export const Label = styled.label`
    font-family: 'Neue Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: #ff6700;
    display: block;
`;

export default Label;
