import styled from 'styled-components';
import React, { ReactNode } from 'react';

interface IProps {
    isFullWidth?: boolean;
    children?: ReactNode;
    className?: string;
}

export const ContainerStyle = styled.div<IProps>`
    padding-right: 12px;
    padding-left: 12px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    ${(props) =>
        props.isFullWidth == false &&
        `
  
      @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px}) {
        max-width: 1140px;
    }
    @media (min-width: 1400px}) {
        max-width: 1320px;
    }
  
  `}
`;

const Container: React.FC<IProps> = (props) => {
    const { isFullWidth = false, children, className } = props;
    return (
        <ContainerStyle isFullWidth={isFullWidth} className={className}>
            {children}
        </ContainerStyle>
    );
};
export default Container;
