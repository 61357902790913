import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../ui/icons/ArrowIcon';
import { Flex } from '../ui/Flex';
import { Form, Formik } from 'formik';
import { IRegisterQuestion } from '../../models/question';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRegister,
    setBackIdImage,
    setBackIdUrl,
    setCurrentQuestion,
    setFaceImage,
    setFaceUrl,
    setFrontIdImage,
    setFrontIdUrl,
    setIsCanSendOtp,
    setShowCheckboxInfo,
} from '../../store/reducers/registerSlice';
import Question1Citizenship from './questions/Question1Citizenship';
import Question5WhatDoYouDo from './questions/Question5WhatDoYouDo';
import Question6CompanyDetails from './questions/Question6CompanyDetails';
import StrengthBar from '../ui/StrengthBar';
import Question7FrontID from './questions/Question7FrontID';
import { QuestionStyle } from './questions/QuestionStyle';
import Question8BackId from './questions/Question8BackId';
import Question9FaceImage from './questions/Question9FaceImage';
import { RegisterModel } from '../../models/register';
import Question2ContactDetails from './questions/Question2ContactDetails';
import Question4BankDetails from './questions/Question4BankDetails';
import Container from '../ui/Container';
import { useRegisterMutation } from '../../store/RTKQuery/auth/register';
import { cloneDeep, range } from 'lodash';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone';
import { WhatDoYouDoEnum } from '../../enums/WhatDoYouDoEnum';
import CheckboxInfo from './questions/CheckboxInfo';
import Question3OTP from './questions/Question3OTP';
import { useTranslation } from 'react-i18next';
import Question10 from './questions/Question10';
import { useGetLookupsQuery } from '../../store/RTKQuery/lookups/getLookups';
import { selectUtility } from '../../store/reducers/utilitySlice';

const RegisterStyle = styled.div`
    background-color: #f1f1f1;
    padding: 30px 20px;
    min-height: 100vh;
    .backIcon {
        cursor: pointer;
        &.rtl {
            transform: rotate(180deg);
        }
    }
    .skip {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0828b2;
        border: none;
        margin-bottom: 10px;
        display: block;
        padding-bottom: 10px;
    }
    .strengthBar {
        margin-bottom: 20px;
    }
    .topPart {
        padding-bottom: 10px;
    }
`;

export const questions: IRegisterQuestion[] = [
    {
        page: 1,
        isOptional: false,
    },
    {
        page: 2,
        isOptional: false,
    },
    {
        page: 3,
        isOptional: false,
    },
    {
        page: 4,
        isOptional: true,
    },

    {
        page: 5,
        isOptional: false,
    },
    {
        page: 6,
        isOptional: false,
    },
    {
        page: 7,
        isOptional: false,
    },
    {
        page: 8,
        isOptional: false,
    },
    {
        page: 9,
        isOptional: false,
    },
    {
        page: 10,
        isOptional: false,
    },
];
interface IProps {}
const schema = (t: any) =>
    yup.object().shape({
        nationality: yup.string().required(t('citizenshipIsRequiredField')),
        full_name: yup.string().required(t('fullNameIsRequiredField')),
        /*
            .matches(/^[A-Za-z\s]*$/, t('Only English letters are allowed.'))
*/
        email: yup
            .string()
            .email(t('emailAddressMustBeValidEmail'))
            .required(t('emailIsRequiredField')),
        mobile: yup
            .string()
            .phone('', true, t('phoneNumberIsNoValid'))
            .required(t('mobileIsRequiredField')),
    });

const schemaWithCompany = (t: any) =>
    yup.object().shape({
        nationality: yup.string().required(t('citizenshipIsRequiredField')),
        full_name: yup.string().required(t('fullNameIsRequiredField')),
        email: yup
            .string()
            .email(t('emailAddressMustBeValidEmail'))
            .required(t('emailIsRequiredField')),
        mobile: yup
            .string()
            .phone(undefined, undefined, t('phoneNumberIsNoValid'))
            .required(t('mobileIsRequiredField')),
        company: yup.object().shape({
            name: yup.string().required(t('companyNameIsRequiredField')),
            job_title: yup.string().required(t('jobTitleIsRequiredField')),
            years: yup
                .string()
                .required(t('numberOfYearsOfEmploymentIsRequiredField')),
        }),
    });

const Register: FC<IProps> = (props) => {
    const {
        currentQuestion,
        current_job,
        showCheckboxInfo,
        frontIdUrl,
        backIdUrl,
        faceUrl,
    } = useSelector(selectRegister);
    const { lang } = useSelector(selectUtility);
    useGetLookupsQuery({
        lang: lang,
    });

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [register, { isLoading, isSuccess }] = useRegisterMutation();
    useEffect(() => {
        dispatch(setCurrentQuestion(questions[0]));
    }, []);
    const reset = () => {
        dispatch(
            setFrontIdImage({
                original: null,
                upload: null,
            }),
        );
        dispatch(setFrontIdUrl(''));
        dispatch(
            setBackIdImage({
                original: null,
                upload: null,
            }),
        );
        dispatch(setBackIdUrl(''));
        dispatch(
            setFaceImage({
                original: null,
                upload: null,
            }),
        );
        dispatch(setFaceUrl(''));
        dispatch(setIsCanSendOtp(true));
    };
    useEffect(() => {
        if (!currentQuestion) {
            dispatch(setCurrentQuestion(questions[0]));
        }
        reset();
    }, []);
    useEffect(() => {
        if (isSuccess) {
            reset();
            navigate('/register-success');
        }
    }, [isSuccess]);
    return (
        <RegisterStyle>
            <Container>
                {currentQuestion && (
                    <>
                        <Flex
                            gap={25}
                            justifyContent={'space-between'}
                            marginBottom={15}
                            className="topPart"
                        >
                            {currentQuestion.page > 0 && !isLoading && (
                                <ArrowIcon
                                    className={'backIcon ' + i18n.dir()}
                                    onClick={() => {
                                        if (currentQuestion) {
                                            if (currentQuestion.page === 1) {
                                                navigate('/pre-register');
                                            } else {
                                                if (
                                                    currentQuestion.page === 4
                                                ) {
                                                    dispatch(
                                                        setCurrentQuestion(
                                                            questions.filter(
                                                                (q) =>
                                                                    q.page ===
                                                                    2,
                                                            )[0],
                                                        ),
                                                    );
                                                } else {
                                                    if (
                                                        currentQuestion.page ===
                                                            10 &&
                                                        showCheckboxInfo.show
                                                    ) {
                                                        dispatch(
                                                            setShowCheckboxInfo(
                                                                {
                                                                    ...showCheckboxInfo,
                                                                    show: false,
                                                                },
                                                            ),
                                                        );
                                                    } else {
                                                        let backPageNumber = 1;
                                                        if (
                                                            current_job !==
                                                                WhatDoYouDoEnum.Employed &&
                                                            currentQuestion.page ===
                                                                7
                                                        ) {
                                                            backPageNumber = 2;
                                                        }
                                                        dispatch(
                                                            setCurrentQuestion(
                                                                questions.filter(
                                                                    (q) =>
                                                                        q.page ===
                                                                        currentQuestion.page -
                                                                            backPageNumber,
                                                                )[0],
                                                            ),
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                />
                            )}
                            {currentQuestion.isOptional && (
                                <button
                                    className="skip"
                                    type={'button'}
                                    onClick={() => {
                                        let number = 1;
                                        if (
                                            currentQuestion.page === 5 &&
                                            current_job !==
                                                WhatDoYouDoEnum.Employed
                                        ) {
                                            number = 2;
                                        }
                                        dispatch(
                                            setCurrentQuestion(
                                                questions.filter(
                                                    (q) =>
                                                        q.page ===
                                                        currentQuestion.page +
                                                            number,
                                                )[0],
                                            ),
                                        );
                                    }}
                                >
                                    {t('skip')}
                                </button>
                            )}
                        </Flex>
                        {currentQuestion.page > 1 && (
                            <StrengthBar
                                strength={currentQuestion.page}
                                className={'strengthBar'}
                            />
                        )}
                        <Formik
                            initialValues={new RegisterModel()}
                            validationSchema={
                                current_job === WhatDoYouDoEnum.Employed
                                    ? schemaWithCompany(t)
                                    : schema(t)
                            }
                            onSubmit={(values, formikHelpers) => {
                                range(1, 4).map((value, index, array) => {
                                    formikHelpers.setFieldValue(
                                        `checkbox${value}`,
                                        true,
                                    );
                                });
                                const cloneDeepValues = cloneDeep(values);
                                cloneDeepValues.front_id_image = frontIdUrl;
                                cloneDeepValues.back_id_image = backIdUrl;
                                cloneDeepValues.face_image = faceUrl;
                                register(cloneDeepValues);
                            }}
                        >
                            {({ errors, touched, isValid, values }) => (
                                <Form>
                                    <QuestionStyle>
                                        {currentQuestion.page === 1 && (
                                            <Question1Citizenship />
                                        )}
                                        {currentQuestion.page === 2 && (
                                            <Question2ContactDetails
                                                errors={errors}
                                                touched={touched}
                                                values={values}
                                            />
                                        )}
                                        {currentQuestion.page === 3 && (
                                            <Question3OTP values={values} />
                                        )}
                                        {currentQuestion.page === 4 && (
                                            <Question4BankDetails
                                                values={values}
                                            />
                                        )}
                                        {currentQuestion.page === 5 && (
                                            <Question5WhatDoYouDo
                                                values={values}
                                            />
                                        )}
                                        {currentQuestion.page === 6 && (
                                            <Question6CompanyDetails
                                                errors={errors}
                                                touched={touched}
                                            />
                                        )}
                                        {currentQuestion.page === 7 && (
                                            <Question7FrontID />
                                        )}
                                        {currentQuestion.page === 8 && (
                                            <Question8BackId />
                                        )}
                                        {currentQuestion.page === 9 && (
                                            <Question9FaceImage />
                                        )}
                                        {currentQuestion.page === 10 && (
                                            <>
                                                {showCheckboxInfo.show ? (
                                                    <CheckboxInfo />
                                                ) : (
                                                    <Question10
                                                        isValid={isValid}
                                                        isLoading={isLoading}
                                                        values={values}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </QuestionStyle>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </Container>
        </RegisterStyle>
    );
};

export default Register;
