import { PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

export async function imgPreview(
    image: HTMLImageElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
) {
    const canvas = document.createElement('canvas');
    const r = await canvasPreview(image, canvas, crop, scale, rotate);
    return canvas.toDataURL();
}
