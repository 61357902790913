import { FC, useEffect } from 'react';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import InputField from '../../ui/fields/input/InputField';
import NextQuestionButton from '../NextQuestionButton';
import PhoneField from '../../ui/fields/input/PhoneField';
import styled from 'styled-components';
import InfoQuestionButton from '../InfoQuestionButton';
import { useTranslation } from 'react-i18next';
import { useCheckEmailMutation } from '../../../store/RTKQuery/auth/checkEmail';
import { setCurrentQuestion } from '../../../store/reducers/registerSlice';
import { questions } from '../Register';
import { useDispatch } from 'react-redux';
const Question2ContactDetailsStyle = styled.div``;

interface IProps {
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    values: FormikValues;
}
const Question2ContactDetails: FC<IProps> = (props) => {
    const { errors, touched, values } = props;
    const { t } = useTranslation();
    const [checkEmail, { isLoading, isSuccess }] = useCheckEmailMutation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (isSuccess) {
            dispatch(
                setCurrentQuestion(questions.filter((q) => q.page === 3)[0]),
            );
        }
    }, [isSuccess]);
    return (
        <Question2ContactDetailsStyle>
            <h3 className="title">
                {t('contactDetails')}
                <InfoQuestionButton />
            </h3>
            <div className="fields">
                <Field
                    label={t('fullNameStar')}
                    placeholder={t('enterYourFirstName')}
                    name={'full_name'}
                    component={InputField}
                />
                <Field
                    label={t('emailStar')}
                    placeholder={t('enterYourEmail')}
                    name={'email'}
                    type={'email'}
                    component={InputField}
                />
                <Field
                    label={t('phoneNumberStar')}
                    placeholder={'+20 111 111 111 1'}
                    name={'mobile'}
                    component={PhoneField}
                />
            </div>
            <NextQuestionButton
                nextPage={3}
                isLoading={isLoading}
                canNext={
                    errors.full_name == null &&
                    errors.email == null &&
                    errors.mobile == null &&
                    touched.full_name === true &&
                    touched.email === true &&
                    touched.mobile === true
                }
                onClick={() => {
                    checkEmail({
                        email: values.email,
                        mobile: values.mobile,
                    });
                }}
            />
            <p className="info">{t('questionInfo')}</p>
        </Question2ContactDetailsStyle>
    );
};
export default Question2ContactDetails;
