import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import registerReducer from './reducers/registerSlice';
import utilityReducer from './reducers/utilitySlice';
import { authApi } from './RTKQuery/auth/authApi';
import { uploadApi } from './RTKQuery/upload/uploadApi';
import { rtkQueryErrorLogger } from './RTKQuery/rtkQueryErrorLogger';
import { lookupsApi } from './RTKQuery/lookups/lookupsApi';

export const store = configureStore({
    reducer: {
        registerSlice: registerReducer,
        utilitySlice: utilityReducer,
        [authApi.reducerPath]: authApi.reducer,
        [uploadApi.reducerPath]: uploadApi.reducer,
        [lookupsApi.reducerPath]: lookupsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            rtkQueryErrorLogger,
            authApi.middleware,
            uploadApi.middleware,
            lookupsApi.middleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
