import { FieldProps, getIn } from 'formik';
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from './Checkbox';
import FieldMessage from '../FieldMessage';
import { isString } from 'lodash';
import Label from '../Label';
import { useTranslation } from 'react-i18next';

const CheckboxFieldStyle = styled.div`
    .field {
        display: flex;
        align-items: flex-start;
        label {
            line-height: 20px;
            color: #494947;
            .link {
                color: #ff6700;
            }
        }
        &.rtl {
            input {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }

    &.error {
        .inputHintMessage {
            margin-left: 25px;
        }
    }
`;

interface IProps extends FieldProps {
    label?: ReactNode | string;
    onChange?: (newValue: boolean) => void;
}

const CheckboxField: React.FC<IProps> = (props) => {
    const {
        label,
        field,
        onChange,
        form: { setFieldValue, errors, touched, handleChange },
        ...restProps
    } = props;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const { i18n } = useTranslation();
    useEffect(() => {
        if (!field.value) {
            setFieldValue(field.name, false);
        }
    }, []);
    return (
        <CheckboxFieldStyle>
            <div className={'field ' + i18n.dir()}>
                <Checkbox
                    {...field}
                    {...restProps}
                    name={field.name}
                    id={field.name}
                    type={'checkbox'}
                    marginRight={5}
                    checked={field.value}
                    onChange={(event) => {
                        handleChange(event);
                        if (onChange) {
                            onChange(event.target.checked);
                        }
                    }}
                />
                <Label htmlFor={field.name}>
                    {label && isString(label) ? <span>{label}</span> : label}
                </Label>
            </div>
            {errorMessage && touch && (
                <FieldMessage className={'inputHintMessage'}>
                    {errorMessage}
                </FieldMessage>
            )}
        </CheckboxFieldStyle>
    );
};
export default CheckboxField;
