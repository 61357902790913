import { lookupsApi } from './lookupsApi';
import { ILookups } from '../../../models/lookups';
import { LanguageType } from '../../reducers/utilitySlice';

const getLookupsApi = lookupsApi.injectEndpoints({
    endpoints: (build) => ({
        getLookups: build.query<ILookups, Partial<{ lang: LanguageType }>>({
            query: (data) => ({
                url: `assets`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetLookupsQuery } = getLookupsApi;
