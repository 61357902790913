import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../ui/Container';
import { Flex } from '../ui/Flex';
import { useNavigate } from 'react-router-dom';
import InfoQuestionButton from './InfoQuestionButton';
import Button from '../ui/Button';
import preRegisterBoxImage from '../../images/pre-register-box-image.png';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../ui/ChangeLanguage';
import { useGetLookupsQuery } from '../../store/RTKQuery/lookups/getLookups';
import { useSelector } from 'react-redux';
import { selectUtility } from '../../store/reducers/utilitySlice';

const PreRegisterStyle = styled.div`
    background-color: #f1f1f1;
    padding: 30px 20px;
    min-height: 100vh;
    .backIcon {
        cursor: pointer;
        transform: rotate(0);
        path {
            transition: fill 0.25s;
        }
        &:hover {
            path {
                fill: red;
            }
        }
    }

    h3.title {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #ff6700;
        margin-bottom: 15px;
        span {
            margin-right: 5px;
        }
        &.rtl {
            span {
                margin-left: 5px;
            }
        }
    }
    p.subtitle {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #292929;
        span {
            color: #0828b2;
        }
        margin-bottom: 15px;
    }

    .box {
        padding: 15px;
        border-radius: 10px;
        background-color: white;
        margin-bottom: 30px;
        h4 {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #0828b2;
        }
        p {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 18px;
            color: #292929;
        }
    }
`;

interface IProps {}

const PreRegister: FC<IProps> = (props) => {
    const navigate = useNavigate();
    const { lang } = useSelector(selectUtility);
    const { t, i18n } = useTranslation();
    useGetLookupsQuery({
        lang: lang,
    });
    return (
        <PreRegisterStyle>
            <Container>
                <Flex gap={25} justifyContent={'flex-end'} marginBottom={25}>
                    <ChangeLanguage />
                </Flex>
                <h3 className={'title ' + i18n.dir()}>
                    <span>{t('openAnAccountAndClaimYour')}</span>
                    <InfoQuestionButton />
                </h3>
                <p className={'subtitle'}>
                    {t('youWillOnlyNeedGovernmentIssuedPhoto')}
                </p>
                <div className="box">
                    <h4>{t('duTradeIsRegisteredAndRegulated')}</h4>
                    <Flex
                        alignItems={'center'}
                        gap={10}
                        justifyContent={'space-between'}
                        wrap={'nowrap'}
                    >
                        <p>
                            {t(
                                'It is a licensed company that is under the supervision of the',
                            ) + ' '}
                            <a
                                href="https://fra.gov.eg/company_records/%d9%85%d8%a8%d8%a7%d8%b4%d8%b1-%d9%84%d8%aa%d8%af%d8%a7%d9%88%d9%84-%d8%a7%d9%84%d8%a7%d9%88%d8%b1%d8%a7%d9%82-%d8%a7%d9%84%d9%85%d8%a7%d9%84%d9%8a%d9%87-%d9%88%d8%a7%d9%84%d8%b3%d9%86%d8%af%d8%a7/"
                                target={'_blank'}
                            >
                                {t(
                                    'General Authority for Financial Supervision',
                                ) + ' '}
                            </a>{' '}
                            {t('and a member of the') + ' '}
                            <a
                                href={
                                    'https://www.egx.com.eg/en/memberfirmdetails.aspx?Code=1159'
                                }
                                target={'_blank'}
                            >
                                {t('Egyptian Stock Exchange') + ' '}
                            </a>{' '}
                            {t('for more than 15 years.') + ' '}
                        </p>
                        <img src={preRegisterBoxImage} alt="" />
                    </Flex>
                </div>
                <Button
                    text={t('next') || ''}
                    handleClick={() => {
                        navigate('/register');
                    }}
                />
            </Container>
        </PreRegisterStyle>
    );
};

export default PreRegister;
