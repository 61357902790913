import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/app.scss';
import { Provider } from 'react-redux';
import { store } from './store';
import { ToastContainer } from 'react-toastify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Layout from './components/Layout';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <HelmetProvider>
                <Provider store={store}>
                    <Helmet>
                        <meta charSet="UTF-8" />
                        <meta
                            name="viewport"
                            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
                        />
                        <link
                            rel="preconnect"
                            href="https://fonts.googleapis.com"
                        />
                        <link
                            rel="preconnect"
                            href="https://fonts.gstatic.com"
                        />
                        <link
                            href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap"
                            rel="stylesheet"
                        />
                        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                        <title>Mubasher Valify</title>
                    </Helmet>
                    <Layout>
                        <App />
                    </Layout>
                    <ToastContainer
                        enableMultiContainer
                        containerId={'errorToastContainer'}
                        toastClassName={'errorToastContainer'}
                    />
                </Provider>
            </HelmetProvider>
        </I18nextProvider>
    </React.StrictMode>,
);
reportWebVitals();
