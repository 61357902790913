import React, { FC, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import slide1Image from '../images/slide1.png';
import slide2Image from '../images/slide2.png';
import slide3Image from '../images/slide3.png';
import { useNavigate } from 'react-router-dom';
import Button from './ui/Button';
import Container from './ui/Container';
import { Flex } from './ui/Flex';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ui/ChangeLanguage';

const MainSlider = styled.div`
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .white-button {
        max-width: 45px;
        margin-left: auto;
        padding: 0;
    }
    .landing-slider {
        margin: 20px 0;
        .slick-dots {
            display: flex !important;
            align-items: center;
            justify-content: center;
            gap: 10px;
            li {
                button {
                    font-size: 0;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #d9d9d9;
                }
                &.slick-active {
                    button {
                        background-color: #ff6700;
                    }
                }
            }
        }
    }
    img {
        margin: 0 auto;
        max-width: 100%;
    }
    .content {
        margin-top: 20px;
        h3 {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-size: 22px;
            font-weight: 700;
            line-height: 27px;
            color: #ff6700;
            margin-bottom: 7px;
            text-align: left;
        }
        p {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #ff6700;
            text-align: left;
        }
    }
    &.rtl {
        h3 {
            text-align: right;
        }
        p {
            text-align: right;
        }
    }
`;

interface IProps {}
const SimpleSlider: FC<IProps> = (props) => {
    const [slideCount] = useState(3);
    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
    const navigate = useNavigate();
    const ref = useRef<any>(null);
    const { t, i18n } = useTranslation();
    const [isRtl, setIsRtl] = useState<boolean>(false);
    useEffect(() => {
        setIsRtl(i18n.dir() === 'rtl');
    }, [i18n]);
    return (
        <MainSlider className={i18n.dir()}>
            <Container>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                    <ChangeLanguage />
                    <button
                        className="skip"
                        type={'button'}
                        onClick={() => {
                            navigate('/pre-register');
                        }}
                    >
                        {t('skip')}
                    </button>
                </Flex>
                <Slider
                    className={'landing-slider'}
                    rtl={isRtl}
                    dots={true}
                    arrows={false}
                    ref={ref}
                    infinite={false}
                    beforeChange={(currentSlide, nextSlide) => {
                        setCurrentSlideNumber(nextSlide);
                    }}
                >
                    <div>
                        <img src={slide1Image} alt="" />
                        <div className="content">
                            <h3>{t('slider.slide1.title')}</h3>
                            <p>{t('slider.slide1.text')}</p>
                        </div>
                    </div>
                    <div>
                        <img src={slide2Image} alt="" />
                        <div className="content">
                            <h3>{t('slider.slide2.title')}</h3>
                            <p>{t('slider.slide2.text')}</p>
                        </div>
                    </div>
                    <div>
                        <img src={slide3Image} alt="" />
                        <div className="content">
                            <h3>{t('slider.slide3.title')}</h3>
                            <p>{t('slider.slide3.text')}</p>
                        </div>
                    </div>
                </Slider>
                <Button
                    handleClick={() => {
                        if (currentSlideNumber + 1 === slideCount) {
                            navigate('/pre-register');
                        } else {
                            ref.current.slickNext();
                        }
                    }}
                    text={
                        currentSlideNumber + 1 === slideCount
                            ? t('getStarted') || ''
                            : t('continue') || ''
                    }
                />
            </Container>
        </MainSlider>
    );
};

export default SimpleSlider;
