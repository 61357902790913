import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../index';

let baseUrl = process.env.REACT_APP_BASE_URL + '/api/';

export const getFetchBaseQueryHeader = (header: any = {}) => {
    return fetchBaseQuery({
        baseUrl: baseUrl,
        headers: header,
        prepareHeaders: (headers, { getState, endpoint }) => {
            headers.set('locale', (getState() as RootState).utilitySlice.lang);
            return headers;
        },
    });
};
