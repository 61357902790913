import React, { FC } from 'react';
import styled from 'styled-components';
import Container from './ui/Container';
import registerSuccessImage from '../images/register-success.png';
import { Flex } from './ui/Flex';
import { useTranslation } from 'react-i18next';
import { ButtonStyle } from './ui/Button';

const RegisterSuccessPage = styled.div`
    h2 {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #ff6700;
        margin-bottom: 15px;
    }
    p {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #292929;
        margin-bottom: 15px;
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        margin-bottom: 15px;
        button {
            width: 50%;
        }
    }
    .or {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 12px;
        color: #b7b7b7;
        margin-bottom: 15px;
    }
    .registerSuccessImage {
        margin-top: 30px;
        max-width: 75%;
    }
`;

interface IProps {}
const RegisterSuccess: FC<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <RegisterSuccessPage>
            <Container>
                <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <img
                        className={'registerSuccessImage'}
                        src={registerSuccessImage}
                        alt=""
                    />
                    <h2>{t('Check Your Email')}</h2>
                    <p>
                        {t(
                            'The registration data has been received successfully. Please check your email now to receive the confirmation message and login details.',
                        )}
                    </p>
                    {/* <div className="actions">
                        <ButtonStyle
                            as={'a'}
                            href={'https://rubixdutrade.dutrade.com/web/login'}
                        >
                            {t('trade') || ''}
                        </ButtonStyle>
                        <ButtonStyle
                            as={'a'}
                            href={'https://rubixdutrade.dutrade.com/web/login'}
                        >
                            {t('competition') || ''}
                        </ButtonStyle>
                    </div>*/}
                </Flex>
            </Container>
        </RegisterSuccessPage>
    );
};

export default RegisterSuccess;
