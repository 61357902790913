import { FieldProps, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import Label from '../Label';
import FieldMessage from '../FieldMessage';
import { ISelectItem } from '../../../../models/select-item';
import { filter, find, isEmpty } from 'lodash';
import SelectFieldStyle from './SelectFieldStyle';

interface IProps extends FieldProps {
    options: ISelectItem[];
    isLoading?: boolean;
    option?: ISelectItem;
    label?: string;
    placeholder?: string;
    isDisabled?: boolean;
    className?: string;
    insideModal?: boolean;
}
const SelectField: React.FC<IProps> = (props) => {
    const {
        options,
        field,
        isLoading,
        option,
        label,
        placeholder,
        insideModal = false,
        isDisabled = false,
        className,
        form: { setFieldValue, errors, touched },
        ...restProps
    } = props;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    const [selectedOption, setSelectedOption] = useState<
        ISelectItem | null | undefined
    >();
    useEffect(() => {
        if (field.value == null) {
            setFieldValue(field.name, '');
        }
    }, []);
    useEffect(() => {
        if (option && !field.value) {
            setSelectedOption(option);
            setFieldValue(field.name, option.value);
        }
    }, [option]);
    useEffect(() => {
        if (!field.value) {
            setSelectedOption(null);
        }
    }, [field.value]);
    useEffect(() => {
        if (isEmpty(options)) {
            setSelectedOption(null);
        } else {
            if (field.value) {
                const currentOption = filter(
                    options,
                    (o) => o.value === field.value,
                )[0];
                setSelectedOption(currentOption);
            }
        }
    }, [options]);
    return (
        <SelectFieldStyle
            className={
                (className ? className + ' ' : '') +
                (isDisabled ? 'disabled ' : '') +
                (errorMessage && touch ? 'error' : '')
            }
        >
            <div className="field">
                {label && (
                    <Label>
                        <span>{label}</span>
                    </Label>
                )}
                <Select
                    {...field}
                    {...restProps}
                    isLoading={isLoading || false}
                    isDisabled={isLoading || isDisabled || false}
                    options={options}
                    value={selectedOption}
                    id={field.name}
                    isSearchable={false}
                    instanceId={field.name}
                    classNamePrefix={'selectField'}
                    placeholder={placeholder || ''}
                    menuPortalTarget={insideModal ? document.body : null}
                    onChange={(newValue: SingleValue<ISelectItem>) => {
                        setFieldValue(field.name, newValue?.value);
                        setSelectedOption(
                            find(options, { value: newValue?.value }),
                        );
                    }}
                />
            </div>
            {errorMessage && touch && (
                <FieldMessage className={'inputHintMessage'}>
                    {errorMessage}
                </FieldMessage>
            )}
        </SelectFieldStyle>
    );
};
export default SelectField;
