import { FC } from 'react';
interface IProps {
    className?: string;
}
const QuestionMark2Icon: FC<IProps> = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0.5C3.84375 0.5 0.5 3.875 0.5 8C0.5 12.1562 3.84375 15.5 8 15.5C12.125 15.5 15.5 12.1562 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 12.5C7.4375 12.5 7 12.0625 7 11.5C7 10.9375 7.4375 10.5 8 10.5C8.5625 10.5 9 10.9375 9 11.5C9 12.0625 8.5625 12.5 8 12.5ZM10.1875 8.0625L8.75 8.9375V9C8.75 9.40625 8.40625 9.75 8 9.75C7.59375 9.75 7.25 9.40625 7.25 9V8.5C7.25 8.25 7.375 8 7.625 7.84375L9.40625 6.78125C9.625 6.65625 9.75 6.4375 9.75 6.1875C9.75 5.8125 9.4375 5.5 9.0625 5.5H7.4375C7.0625 5.5 6.75 5.8125 6.75 6.1875C6.75 6.59375 6.40625 6.9375 6 6.9375C5.59375 6.9375 5.25 6.59375 5.25 6.1875C5.25 4.96875 6.21875 4 7.4375 4H9.0625C10.2812 4 11.25 4.96875 11.25 6.1875C11.25 6.9375 10.8438 7.65625 10.1875 8.0625Z"
                fill="#FF6700"
            />
        </svg>
    );
};
export default QuestionMark2Icon;
