import { CountdownRenderProps, zeroPad } from 'react-countdown';
import React from 'react';

export const CountDownRender = ({ minutes, seconds }: CountdownRenderProps) => {
    return (
        <p className={'textCenter w100'}>
            {/*Resend code in */}
            {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
    );
};
