import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Loader from './Loader';

export const ButtonStyle = styled.button<IProps>`
    cursor: pointer;
    font-family: 'Neue Helvetica', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #f6f4f3;
    padding: 15px 25px;
    background-color: #ff6700;
    border-radius: 10px;
    width: 100%;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:disabled {
        background-color: #d7d7d7;
        border-color: #d7d7d7;
        color: #bfbfbf;
        cursor: default;
        span {
            color: #bfbfbf;
        }
    }
    ${(props) =>
        props.padding &&
        `
            padding: ${props.padding.y}px ${props.padding.x}px;
            `}
    span {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #f6f4f3;
    }
    ${(props) =>
        props.variant === 'outline' &&
        `
  background-color: transparent;
    border-color: #FF6700;

  color:#FF6700;
  span {
    color:#FF6700;

  }
  `}
`;

interface IProps {
    isLoading?: boolean;
    text?: string;
    className?: string;
    icon?: ReactNode;
    variant?: 'primary' | 'outline';
    type?: 'button' | 'submit';
    handleClick?: () => void;
    disabled?: boolean;
    padding?: { x: number; y: number };
}

const Button: React.FC<IProps> = (props) => {
    const {
        isLoading = false,
        text,
        icon,
        className,
        type = 'button',
        variant = 'primary',
        handleClick,
        disabled = false,
        padding,
    } = props;
    return (
        <ButtonStyle
            text={text}
            className={className}
            type={type}
            variant={variant}
            onClick={handleClick}
            disabled={disabled}
            padding={padding}
        >
            {icon && icon}
            {isLoading && <Loader />}
            <span>{text}</span>
        </ButtonStyle>
    );
};
export default Button;
