import { authApi } from './authApi';

const checkEmailApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        checkEmail: build.mutation<
            {
                success: boolean;
                message: string;
            },
            Partial<{ email: string; mobile: string }>
        >({
            query: (data) => ({
                url: `checkemail`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useCheckEmailMutation } = checkEmailApi;
