import styled from 'styled-components';

export const FieldMessage = styled.p`
    font-family: 'Neue Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: #e84228;
    margin-top: 10px;
`;

export default FieldMessage;
