import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelCrop,
} from 'react-image-crop';
import Slider from 'rc-slider';
import { Flex } from '../../ui/Flex';
import Button from '../../ui/Button';
import { imgPreview } from '../../../utils/imgPreview';
import { useTranslation } from 'react-i18next';

const CaptureImageStyle = styled.div`
    width: 100%;

    .slider {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        .rc-slider-track {
            background-color: #ff6700;
        }
        .rc-slider-handle {
            border-color: #ff6700;
            opacity: 1;
            &:focus-visible,
            &:active {
                box-shadow: 0 0 0 3px #ff6700 !important;
            }
        }
        .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
            box-shadow: 0 0 0 3px #ff6700 !important;
        }
    }
`;
interface IProps {
    onEdit: (imageSrc: string) => void;
    image: File;
    onBack: () => void;
}
const EditImage: FC<IProps> = (props) => {
    const { onEdit, image, onBack } = props;
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<PixelCrop>();
    const [rotate, setRotate] = useState(0);
    const { t } = useTranslation();
    return (
        <CaptureImageStyle>
            <Flex direction={'column'} alignItems={'center'} gap={30}>
                <div className="crop">
                    <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop, percentCrop) =>
                            setCrop(pixelCrop)
                        }
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            style={{ transform: `rotate(${rotate}deg)` }}
                            src={URL.createObjectURL(image)}
                            onLoad={(event) => {
                                if (event.currentTarget && !crop) {
                                    setCrop({
                                        x: 0,
                                        y: 0,
                                        width: event.currentTarget.width,
                                        height: event.currentTarget.height,
                                        unit: 'px',
                                    });
                                }
                            }}
                        />
                    </ReactCrop>
                </div>
                <div className={'slider'}>
                    <Slider
                        min={0}
                        max={360}
                        step={45}
                        onChange={(value) => {
                            setRotate(value as number);
                        }}
                    />
                </div>
            </Flex>
            <Flex alignItems={'center'} gap={15} wrap={'nowrap'}>
                <Button
                    text={t('back') || ''}
                    variant={'outline'}
                    handleClick={() => {
                        onBack();
                    }}
                />
                <Button
                    text={t('ok') || ''}
                    handleClick={async () => {
                        if (imgRef.current && crop) {
                            const newImage = await imgPreview(
                                imgRef.current,
                                crop,
                                1,
                                rotate,
                            );
                            onEdit(newImage);
                        }
                    }}
                />
            </Flex>
        </CaptureImageStyle>
    );
};
export default EditImage;
